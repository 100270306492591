import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { generalRouteNames, provideGeneralRouteNames } from '@medrecord/routes-general';
import { authRouteNames, provideAuthRouteNames } from '@medrecord/routes-auth';
import { provideAdminRouteNames } from '@routes/admin';
import { provideMedsafeRouteNames } from '@medrecord/routes-medsafe';
import { AuthorizedGuard } from '@medrecord/managers-auth';
import { GetUserMetadataGuard } from '@medrecord/managers-users';
import { RoleAccessGuard } from './guards/role-access.guard';

export const APP_ZDB_ADMIN_ROUTES = [
  {
    path: generalRouteNames.Auth.Entry,
    children: [
      {
        path: authRouteNames.Base,
        loadChildren: () => import('@medrecord/local-auth-view').then((m) => m.LocalAuthViewModule),
      },
    ],
  },
  {
    path: generalRouteNames.Base,
    canActivate: [AuthorizedGuard, GetUserMetadataGuard],
    children: [
      {
        path: generalRouteNames.Admin.Entry,
        canActivate: [RoleAccessGuard],
        loadChildren: () => import('@zdb/admin-shell').then((m) => m.ZdbAdminShellModule),
      },
      {
        path: generalRouteNames.Base,
        redirectTo: generalRouteNames.Admin.Entry,
        pathMatch: 'full' 
      },
    ],
  },
  {
    path: generalRouteNames.Any,
    redirectTo: generalRouteNames.Admin.Entry,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ZDB_ADMIN_ROUTES, {
      initialNavigation: 'enabled',
      // enableTracing: true
    }),
  ],
  providers: [
    provideGeneralRouteNames(),
    provideAuthRouteNames(generalRouteNames.Auth.Entry),
    provideAdminRouteNames(generalRouteNames.Admin.Entry),
    provideMedsafeRouteNames(generalRouteNames.Medsafe.Entry),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
