import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { MedrecordSpinnerToolModule } from '@tools/spinner';
import { MedrecordSubscriptionsModule } from '@tools/subscriptions';

import { MedrecordCommonModule } from '@medrecord/common';
import {
  EnvService,
  provideConstants,
  provideDefaultLang,
  provideStorageKey
} from '@medrecord/core';
import {
  AuthManagerModule,
  provideTokenInitialization,
  provideAuthorizationInterceptor,
} from '@medrecord/managers-auth';
import { TranslationsManagementModule } from '@medrecord/translations-management';
import { MedrecordStorageModule } from '@medrecord/tools-storage';
import { MedrecordCustomerMetadataModule } from '@medrecord/services-customer-metadata';
import { MedrecordToastToolModule } from '@medrecord/tools-toast';
import { MedrecordNavigationModule } from '@medrecord/tools-navigation';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { MedrecordSocialLoginModule } from '@medrecord/services-social-login';
import { AuthSharedModule } from '@medrecord/auth-shared';
import { UsersManagerModule } from '@medrecord/managers-users';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { createTranslateLoader } from './factories/create-translation-loader.factory';
import { provideGoogleAuthConfig } from './providers/provide-google-auth-config';
import { RoleAccessGuard } from './guards/role-access.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    /** Tools */
    MedrecordSpinnerToolModule,
    MedrecordCustomerMetadataModule,
    MedrecordToastToolModule,
    MedrecordDatetimeModule,
    MedrecordNavigationModule,
    MedrecordSubscriptionsModule,
    /** Tools End */
    
    MedrecordSocialLoginModule.forRoot(),
    MedrecordCommonModule,
    MedrecordStorageModule,
    AuthSharedModule,
    AuthManagerModule,
    UsersManagerModule,

    TranslationsManagementModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, EnvService]
      }
    }),

    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),

    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      colorScheme: ['purple', 'teal', 'blue', 'gray', 'red', 'red', 'red']
    }),
  ],
  providers: [
    provideGoogleAuthConfig(),
    provideAuthorizationInterceptor(),
    
    provideDefaultLang(),
    provideStorageKey(),
    provideConstants(),
    provideTokenInitialization(),

    RoleAccessGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
