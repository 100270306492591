import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { generalRouteNames } from '@medrecord/routes-general';
import { logout, Role, selectRoles, selectUserId } from '@medrecord/managers-auth';
import { selectCurrentID } from '@medrecord/managers-users';
import { addInfoToast } from '@medrecord/tools-toast';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RoleAccessGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private translateService: TranslateService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectRoles).pipe(
      withLatestFrom(this.store.select(selectCurrentID), this.store.select(selectUserId)),
      first(),
      map(([roles]) => {
        if (!roles || !roles.length) {
          return this.router.parseUrl(`/${generalRouteNames.Auth.Entry}`);
        }

        if (route.url?.[0].path !== 'redirect' && (roles.includes(Role.Administrator) || roles.includes(Role.Doctor))) return true;

        if (roles.includes(Role.Administrator) || roles.includes(Role.Doctor)) {
          return this.router.parseUrl(generalRouteNames.Admin.Entry);
        }

        if (roles.length === 1 && roles.includes(Role.Patient)) {
          this.store.dispatch(addInfoToast({
            title: this.translateService.instant('patient_login_info_title'),
            content: this.translateService.instant('patient_login_info_content') })
          )
          this.store.dispatch(logout())
        }

        return this.router.parseUrl(`/${generalRouteNames.Auth.Entry}`);
      })
    );
  }
}
